import { axios } from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchExpiringInsurances(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/reminders', { params: queryParams })
          .then(response => (resolve(response)))
          .catch(error => reject(error))
      })
    },
    singleReminder(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/api/v1/reminders/${params.id}/single_send`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    bulkReminder() {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/v1/reminders/bulk_send')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
