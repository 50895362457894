<template>
  <div>
    <v-card id="reminder-list">
      <v-card-text class="d-flex flex-row align-center flex-wrap">
        <div class="text-h6">
          Insurance expire in {{ nextMonth() }}
        </div>
        <v-spacer></v-spacer>
        <div class="col-sm-3 px-0 py-2 col-12">
          <!-- filter sms send -->
          <v-select
            v-model="smsSent"
            :items="sendFilters"
            item-value="id"
            item-text="name"
            dense
            outlined
            clearable
            hide-details="auto"
            :placeholder="t('Select SMS Send')"
            @change="fetchExpiringInsurances()"
          ></v-select>
        </div>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text class="pt-5">
        <v-row>
          <v-col
            lg="2"
            cols="3"
          >
            <div class="d-flex align-center">
              <v-select
                v-model="tablePagination.per"
                :items="listLengthOptions"
                single-line
                outlined
                dense
                hide-details
                class="reminder-list-row-selection"
                @input="listLengthChange()"
              ></v-select>
              <div class="pl-3">
                {{ t('Rows') }}
              </div>
            </div>
          </v-col>

          <v-col
            lg="10"
            cols="9"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="tablePagination.current_page"
              total-visible="6"
              :length="tablePagination.total_page"
              @input="pageChange()"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>

      <!-- table -->
      <v-data-table
        :headers="tableColumns"
        :items="expiringInsurances"
        :options.sync="options"
        :items-per-page="100"
        :loading="loading"
        mobile-breakpoint="0"
        hide-default-footer
        :disable-sort="true"
        class="text-no-wrap"
      >
        <!-- Actions -->
        <template #[`item.actions`]="{item}">
          <div class="d-flex align-center justify-center">
            <!-- Send SMS -->
            <v-tooltip
              bottom
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  v-bind="attrs"
                  v-on="on"
                  @click="isSendSmsDialogVisible = true; receiptSms = item"
                >
                  <v-icon size="18">
                    {{ icons.mdiMessageProcessingOutline }}
                  </v-icon>
                </v-btn>
              </template>
              <span>
                Send SMS reminder
              </span>
            </v-tooltip>
          </div>
        </template>

        <!-- Expiry Status -->
        <template #[`item.expiry_sms_status`]="{item}">
          <v-chip
            label
            color="primary"
            :class="`v-chip-light-bg my-auto ${smsStatusColor(item.expiry_sms_status)}--text`"
          >
            {{ item.expiry_sms_status || 'Not sent' }}
          </v-chip>
        </template>

        <!-- Receipt Number -->
        <template #[`item.receipt_number`]="{item}">
          <router-link
            class="font-weight-medium text-decoration-none"
            :to="{ name: 'apps-receipt-preview', params: { id: item.id } }"
          >
            {{ item.receipt_number }}
          </router-link>
        </template>
      </v-data-table>

      <v-card-text class="pt-5">
        <v-row>
          <v-col
            lg="2"
            cols="3"
          >
            <div class="d-flex align-center">
              <v-select
                v-model="tablePagination.per"
                :items="listLengthOptions"
                single-line
                outlined
                dense
                hide-details
                class="reminder-list-row-selection"
                @input="listLengthChange()"
              ></v-select>
              <div class="pl-3">
                {{ t('Rows') }}
              </div>
            </div>
          </v-col>

          <v-col
            lg="10"
            cols="9"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="tablePagination.current_page"
              total-visible="6"
              :length="tablePagination.total_page"
              @input="pageChange()"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <div class="text-right">
      <v-btn
        v-if="expiryCount > 0"
        color="primary"
        dark
        :block="$vuetify.breakpoint.xsOnly"
        :loading="loadingSend"
        :disabled="loadingSend"
        class="receipt-button mt-5"
        :class="{ 'loading-color': loadingSend && !$vuetify.theme.dark }"
        @click="isSendSmsDialogVisible = true; receiptSms = {}"
      >
        <span>Send Reminders</span>
      </v-btn>
    </div>

    <!-- Confirmation dialog -->
    <v-dialog
      v-model="isSendSmsDialogVisible"
      width="500"
    >
      <v-card>
        <v-card-title class="d-flex align-center mv-4">
          Send SMS reminder
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="isSendSmsDialogVisible = false"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text v-if="receiptSms.id">
          Send SMS reminder to contact number {{ receiptSms.customer_contact_number }} to notify their insurance is expiring soon.
        </v-card-text>
        <v-card-text v-else>
          This action will send sms reminders to customers who haven't received any reminders.
        </v-card-text>

        <v-card-actions class="mt-2">
          <v-btn
            color="primary"
            dark
            block
            :loading="loadingSend"
            :disabled="loadingSend"
            class="receipt-button mt-5"
            :class="{ 'loading-color': loadingSend && !$vuetify.theme.dark }"
            @click="receiptSms.id ? sendSingleReminder() : sendReminder()"
          >
            {{ receiptSms.id ? 'Send Reminder' : 'Send Reminders' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  onUnmounted,
  onMounted,
  ref,
  inject,
  computed,
} from '@vue/composition-api'
import {
  mdiClose,
  mdiMessageProcessingOutline,
} from '@mdi/js'
import notificationService from '@/services/notification.service'
import reminderStoreModule from './reminderStoreModule'

export default {
  setup() {
    // Initializers
    const REMINDER_STORE_MODULE_NAME = 'app-reminder'
    const store = inject('store')
    const snackbarService = inject('snackbarService')
    const t = inject('t')
    const can = inject('can')

    if (!store.hasModule(REMINDER_STORE_MODULE_NAME)) {
      store.registerModule(REMINDER_STORE_MODULE_NAME, reminderStoreModule)
    }

    // Table Handlers
    const tableColumns = computed(() => (
      [
        { text: t('Actions'), value: 'actions', align: 'center' },
        { text: t('SMS Reminder'), value: 'expiry_sms_status' },
        { text: t('#Receipt No.'), value: 'receipt_number' },
        { text: t('Issue Date'), value: 'issued_at' },
        { text: t('Customer Name'), value: 'customer_name' },
        { text: t('Contact No.'), value: 'customer_contact_number' },
        { text: t('Car Registration No.'), value: 'car_registration_number' },
      ]
    ))

    // Properties
    const expiringInsurances = ref([])
    const tablePagination = ref({
      current_page: 1,
      per: 20,
      total_count: 0,
      total_page: 0,
    })
    const options = ref({
      sortBy: ['id'],
      sortDesc: [false],
    })
    const loading = ref(false)
    const loadingSend = ref(false)
    const listLengthOptions = ref([20, 50, 100])
    const expiryCount = ref(null)
    const isSendSmsDialogVisible = ref(false)
    const receiptSms = ref({})
    const smsSent = ref(null)
    const sendFilters = ref([
      { id: 'sent', name: 'Show sent SMS' },
      { id: 'unsent', name: 'Show unsent SMS' },
    ])

    // Methods
    const isLoading = () => {
      loading.value = true
      snackbarService.info('Loading your list. Please hold...')
    }
    const doneLoading = () => {
      loading.value = false
      snackbarService.success('Done loading your list!')
    }
    const fetchExpiringInsurances = () => {
      store
        .dispatch('app-reminder/fetchExpiringInsurances', {
          sms_sent: smsSent.value,
          page: tablePagination.value.current_page,
          per: tablePagination.value.per,
        })
        .then(response => {
          const { data, pagination } = response.data
          expiringInsurances.value = data
          tablePagination.value = pagination
          expiryCount.value = response.data.expiry_count
          doneLoading()
        })
        .catch(error => {
          loading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching expiring insurance. Please refresh!')
        })
    }
    const pageChange = () => {
      isLoading()
      fetchExpiringInsurances()
    }
    const listLengthChange = () => {
      tablePagination.value.current_page = 1
      isLoading()
      fetchExpiringInsurances()
    }
    const nextMonth = () => {
      let date = new Date()
      if (date.getMonth() === 11) {
        date = new Date(date.getFullYear() + 1, 0, 1)
      } else {
        date = new Date(date.getFullYear(), date.getMonth() + 1, 1)
      }
      const month = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

      return `${month[date.getMonth()]} ${date.getFullYear()}`
    }
    const sendSingleReminder = () => {
      loadingSend.value = true
      store
        .dispatch('app-reminder/singleReminder', { id: receiptSms.value.id })
        .then(() => {
          snackbarService.success('Sending SMS reminder!')
          loadingSend.value = false
          isSendSmsDialogVisible.value = false
        })
        .catch(() => {
          loadingSend.value = false
          snackbarService.error('Something went wrong while sending reminder. Please refresh!')
        })
    }
    const sendReminder = () => {
      loadingSend.value = true
      store
        .dispatch('app-reminder/bulkReminder')
        .then(() => {
          snackbarService.success(`Sending ${expiryCount.value} SMS reminder!`)
          expiryCount.value = 0
          loadingSend.value = false
          isSendSmsDialogVisible.value = false
        })
        .catch(() => {
          loadingSend.value = false
          snackbarService.error('Something went wrong while sending reminder. Please refresh!')
        })
    }
    const smsStatusColor = status => {
      switch (status) {
        case 'Submitted':
          return 'success'
        case 'Delivered':
          return 'success'
        case 'Rejected':
          return 'error'
        case 'Failed':
          return 'error'
        default:
          return 'secondary'
      }
    }
    const updateExpirySmsStatus = () => {
      notificationService.subscribeToReminder({
        connected() {
          console.log('Connected to the reminder channel')
        },
        received(data) {
          const receipt = expiringInsurances.value.find(r => r.id === data.id)
          receipt[data.attr] = data.value
        },
      })
    }

    onMounted(() => {
      fetchExpiringInsurances()
      updateExpirySmsStatus()
    })

    // Unmounted
    onUnmounted(() => {
      if (store.hasModule(REMINDER_STORE_MODULE_NAME)) store.unregisterModule(REMINDER_STORE_MODULE_NAME)
    })

    return {
      t,
      can,
      loading,
      loadingSend,
      expiringInsurances,
      tablePagination,
      tableColumns,
      listLengthOptions,
      options,
      fetchExpiringInsurances,
      pageChange,
      listLengthChange,
      sendSingleReminder,
      sendReminder,
      expiryCount,
      isSendSmsDialogVisible,
      receiptSms,
      smsSent,
      sendFilters,
      nextMonth,
      smsStatusColor,

      icons: {
        mdiClose,
        mdiMessageProcessingOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
  #reminder-list {
    .reminder-list-row-selection {
      max-width: 5.3rem;
    }
  }

  .receipt-button {
    width: 251px;
  }

  ::v-deep .theme--dark.v-btn.v-btn--disabled.v-btn--has-bg.loading-color {
    background: #d3d3d3 !important;
    color: #eee !important;
  }

  .dark-divider {
    border-right: 1px solid rgba(231, 227, 252, 0.14) !important;
  }

  .light-divider {
    border-right: 1px solid rgba(94, 86, 105, 0.14);
  }
</style>
